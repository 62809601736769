/**
* gestione file form
* */
function uploadedFileList(current){
	let output = '';
	for(let i =0; i < current.files.length; i++){
		output += output==""?current.files[i].name:", "+current.files[i].name;
	}
	$('#upload-file-info').html(output);
}


$(document).ready(function() {
	// Modal
	let modal       = new bootstrap.Modal(document.getElementById('baseModal'), {
		keyboard: false,
		backdrop: 'static',
		focus: true
	});
	let modaltitle  = $('#modal-title');
	let modaltext   = $('#modal-body');


	//Select2
	$('.select2').select2();



	function submitAJAXForm( _this ) {
		event.preventDefault();
		modaltitle.text('');
		modaltext.text('');

		let spinner = $('#loader');
		let formObj     = _this.parents( 'form' ),
			formData	= new FormData(formObj[0]),
			actionURL   = formObj.find( '[name="action"]' ).val(), //cambiato
			redirectVal = formObj.find( '[name="redirectval"]' ).val();

		let elem = document.getElementById(formObj[0].id).elements;
		let i = 0
		while( i < elem.length )    {
			if(elem[i].name != 'action') {
				formData.append(elem[i].name, elem[i].value);
			}
			i++;
		}

		if( actionURL != '' && actionURL != undefined ) {
			spinner.show();
			$.ajax({
				type	    : 'POST',
				/*mode	    : 'POST',*/
				url		    : actionURL,
				data	    : formData ? formData : formObj.serialize(),
				/*dataType    : 'json',*/
				cache       : false,
				contentType : false,
				processData : false,
				success: function ( result ) {
					result.redirectVal = undefined;
					spinner.hide();
					formObj.find( 'input[name=action],input[name=g-recaptcha-response],input[name=invoice-required]' ).remove();
					// formObj.find( 'input[name=rememberCheckbox]' ).remove();
					if ( typeof ( result ) !== 'undefined' && result !== null ) {
						if (typeof result === 'string' || result instanceof String){
							result = $.parseJSON( result );
							//console.log(result);
						}

						formObj.find( 'input[type=text],input[type=email],input[type=tel],input[type=password],textarea' ).each( function () {
							$( this ).val('');
							$( this ).removeClass( 'error' );
							// Anche label? Manca select
						});
						formObj.find( '.g-recaptcha' ).removeClass( 'error' );
						formObj.find( 'input[type=checkbox],input[type=radio]' ).prop( 'checked', false );
						if( formObj.find( '.g-recaptcha' ).length > 0 ) {
							grecaptcha.reset();
						}

						if(result.status || (result.success && result.success != 'error')){
							window.dataLayer = window.dataLayer || []
							dataLayer.push({
								'event': 'formSubmission',
								'formType': formObj.find( '[name="formname"]' ).val(),
								'formPosition': formObj.find( 'input[name="formposition"]' ).val()
							});

							if(result.status || (result.success && result.success != 'false')) {
								modaltitle.html(result.title).text();
								modaltext.html(result.message).text();
								modal.show();
								if(typeof result.redirectVal !== 'undefined') {
									$("#baseModal").on('hidden.bs.modal', function () {
										window.location.href = result.redirectVal;
									});
								}
							}else{
								modaltitle.html(result.title).text();
								if(result.message.includes(' *')) {
									modaltext.html(result.message.replace(' *', '!')).text();
								} else{
									modaltext.html(result.message).text();
								}
								modal.show();
							}
						}else{
							modaltitle.html(result.title).text();
							if(result.message.includes(' *')) {
								modaltext.html(result.message.replace(' *', '!')).text();
							} else{
								modaltext.html(result.message).text();
							}
							modal.show();
						}
					}
				}
			});
		}
	}


	$(document).on('click', '.submit', function (event) {
		event.preventDefault();
		let _this = $(this);
		let error = false,
			captchaFlag = false,
			formObj = _this.parents('form'),
			emailFormat = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
			telFormat = /[0-9 -()+]{8,}?$/,
			actionURL = formObj.attr('action'),
			gRecaptchaV3 = _this.attr('data-sitekey') || '';

		formObj.find('input[required]').removeClass('error');
		formObj.find('.help-block').text('');

		formObj.find('input[required], textarea[required]').each(function () { //======>.required
			let __this      = $(this),
				fieldVal    = __this.val(),
				fieldValMex = __this.attr('data-validationmessage');
			if (fieldVal == '' || fieldVal == undefined) {
				error = true;
				__this.addClass('error');
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'checkbox' && !__this.prop('checked')) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'email' && !emailFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no valid email!');
			} else if (__this.attr('type') == 'tel' && !telFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no valid tel!');
			}
		});

		// Google reCaptcha Verify
		if (typeof (grecaptcha) !== 'undefined' && grecaptcha !== null) {
			if (formObj.find('.g-recaptcha').length > 0) { // For Version 2
				let gResponse = grecaptcha.getResponse();
				if (!(gResponse.length)) {
					error = true;
					formObj.find('.g-recaptcha').addClass('error');
				}
			} else if (gRecaptchaV3 != '' && gRecaptchaV3 != undefined) { // For Version 3
				captchaFlag = true;
				formObj.find('input[name=action],input[name=g-recaptcha-response]').remove();
				grecaptcha.ready(function () {
					grecaptcha.execute(gRecaptchaV3, {action: 'form'}).then(function (token) {
						formObj.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
						formObj.prepend('<input type="hidden" name="action" value="form">');

						if (!error) {
							submitAJAXForm(_this);
						}
					});
				});
			}
		}
	});

});





const ready = (callback) => {

	if (document.readyState !== 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {

	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			// let headerOffset = $('#headerlanding').outerHeight(true);
			// let offsetPosition = elementPosition - headerOffset;
			let offsetPosition = elementPosition;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});

		};
	});




	// BACK TO TOP

	const topButton = document.getElementById('top');

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}


	// // BACK TO TOP TOGGLE VISIBLITY

	function toggleBacktotop() {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			topButton.style.display = 'block';
		} else {
			topButton.style.display = 'none';
		}
	}

	topButton.addEventListener('click', backToTop);

	window.onscroll = function () {

		toggleBacktotop();

	};



	// SWIPER CAROUSELS

	// Bug pagination with loop: total number of slides must be >= slidesPerView * 2 => use rewind

	const swiperImgText = new Swiper('[id^=swiper-imgtext-]', {
		// loop: true,
		rewind: true,
		slidesPerView: 1,
		grabCursor: true,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 4500,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});

	const swiperTextImg = new Swiper('[id^=swiper-textimg-]', {
		// loop: true,
		rewind: true,
		slidesPerView: 1,
		grabCursor: true,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 4500,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});

	const swiperCarouselBox = new Swiper('[id^=swiper-carousel-box-]', {
		// loop: true,
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 10,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			1200: {
				slidesPerView: 4
			},
			991: {
				slidesPerView: 3
			},
			767: {
				slidesPerView: 2
			}
		}

	});

	const swiperCarouselThreeCentered = new Swiper('[id^=swiper-three_centered-]', {
		centeredSlides: true,
		spaceBetween: 10,
		// loop: true,
		rewind: true,
		slidesPerView: 1,
		grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			991: { slidesPerView: 2 },
			767: { slidesPerView: 1 }
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});


});